import Confirm from './confirm';

export default Confirm.create({});

//  exemplo de uso
//  import confirm from '~/components/confirm'
//
//  const result = await confirm.show({
//     title: 'Inativação',
//     text: 'Deseja realmente inativar esse usuário?'
//  });
